# # смена favicon
  #
  #
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_2.png'
  return
), 10000
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_3.png'
  return
), 20000
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_4.png'
  return
), 30000
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_5.png'
  return
), 40000
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_6.png'
  return
), 50000
setTimeout (->
  document.getElementById('favicon').href = 'assets/magistrale/images/favicons/favicon_1.png'
  return
), 60000
# ==========================================
